import { theme } from "@chakra-ui/core"

// Let's say you want to add custom colors
export default {
  ...theme,
  colors: {
    ...theme.colors,
    brand: {
      900: "#142031",
    },
  },
  fonts: {
    body: "Sofia Pro, sans-serif",
    heading: "Sofia Pro, serif",
    mono: "Sofia Pro, monospace",
  },
}
